import React from "react";
import useStyles from "./styles";
import { Typography, Grid } from "@material-ui/core";

import book from "./images/awaCover.jpg";
import kindle from "./images/kindle.png";

const BookHeader = () => {
  const classes = useStyles();
  return (
    <div className={classes.divOfImg}>
      <Grid container justify="center" align="center" alignItems="center" className={classes.divImg}>
        <Grid item xs={12} md={6}>
          <Grid direction="column" justify="center" container>
            <Grid item>
              <Typography className={classes.titleText}>DREAMers Nightmare</Typography>
            </Grid>
            <Grid item>
              <Typography className={classes.subtitle}>THE U.S. WAR ON IMMIGRANT LATINX CHILDREN</Typography>
            </Grid>
            <Grid item>
              <Grid container justify="space-around" align="center" alignItems="center">
                <Grid item>
                  <img className={classes.bookImg} src={kindle} alt="kindle" onClick={() => window.open("https://www.amazon.com/Arturo-Jim%C3%A9nez/e/B07Y4351T4/ref=dp_byline_cont_pop_book_1", "_blank")} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <img onClick={() => window.open("https://www.amazon.com/Arturo-Jim%C3%A9nez/e/B07Y4351T4/ref=dp_byline_cont_pop_book_1", "_blank")} className={classes.bookImg} src={book} alt="book" />
        </Grid>
      </Grid>
    </div>
  );
};

export default BookHeader;
