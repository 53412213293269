import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
    root: {
        paddingTop: "100px",
        paddingBottom: "100px",
        backgroundColor: "#553a62",
        backgroundImage: `url("https://www.transparenttextures.com/patterns/back-pattern.png")`,
      },
      outline: {
        [theme.breakpoints.up("md")]: {
          width: "20vw",
          margin: "60px auto",
          outline: "5px solid #553a62",
        },
        cursor: "pointer",
        border: "1px solid white",
        margin: "7px 10px",
        backgroundColor: "#553a62",
      },
      circle: {
        border: "1px",
        backgroundColor: "white",
        borderRadius: "10px",
      },
      iconBox: {
        backgroundColor: "rgba(85, 58, 98, 0.75)",
        width: "100px",
        height: "100px",
        borderColor: "white",
        borderRadius: "100px",
        display: "flex",
        alignItems: "center",
        align: "center",
        justifyContent: "center",
      },
      icon: {
        color: "white",
      },
      statNum: {
        color: "white",
        fontWeight: "900",
        fontFamily: `'Montserrat', sans-serif;`,
        fontSize: "2rem",
      },
      statInfo: {
        color: "white",
        fontSize: "1.5rem",
        fontFamily: `'Montserrat', sans-serif;`,
      },

}))
