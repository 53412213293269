import {makeStyles} from '@material-ui/core/styles'

export default makeStyles((theme) => ({
    head: {
        marginTop: '100px',
        paddingTop: '100px',
        paddingBottom: '100px',
        backgroundColor: "#553a62",
        backgroundImage: `url("https://www.transparenttextures.com/patterns/back-pattern.png")`,
        color: 'white',
    },
    gridRoot: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        paddingTop: '50px'

    },
    mainGrid:{
        backgroundColor: '#553a62',
        paddingBottom: '30px',
        paddingTop: '30px',
    },
    review: {
        fontFamily: `'Shippori Mincho B1', serif`,
        color: 'white',
        fontSize: '2rem',
    },
    title: {
        fontFamily: `'Shippori Mincho B1', serif`,
        color: 'white',
        fontSize: '3rem'
    },
    reviewBox: {
        width: '80%',

    },
    gridList: {
        width: '60vw',
        height: '60vh',
        [theme.breakpoints.down('md')]: {
            width: '100%',
            height: '100%',
        }
      },
    avatar: {
        color:'black',
        fontFamily: `'Shippori Mincho B1', serif`,
        fontWeight: '900',
    },
    purple: {
        backgroundColor: 'white'
    }

}))