import {makeStyles} from '@material-ui/core/styles';

export default makeStyles((theme) => ({
      grid: {
        marginTop: "50px",
        marginBottom: "50px",
      },
      paper1: {
          paddingBottom: '100px'
      },
      mainTitle: {
        fontSize: "3rem",
        fontFamily: `'Shippori Mincho B1', serif`,
        fontWeight: "800",
        paddingBottom: "20px",
      },
      subTitle: {
        fontSize: "2rem",
        marginTop: '25px',
        fontWeight: '900',
        marginLeft: '25px',
        fontFamily: `'Montserrat', sans-serif;`,
      },
      bullets: {
        fontFamily: `'Montserrat', sans-serif;`,
        fontSize: '1.3rem',
        marginLeft: '25px'

      },
      title3: {
        fontFamily: `'Shippori Mincho B1', serif`,
        fontWeight: '900',
        fontSize: '1.5rem',
        marginLeft: '50px'
      }, 
      docBody: {
          marginTop: '50px',
        fontFamily: `'Montserrat', sans-serif;`,
        marginLeft: '50px',
        fontSize: '1.3rem',

      },
      image: {
          margin: 'auto 0'
      }
}))