import React from "react";
import { Typography, List } from "@material-ui/core";
import ListItemz from "./ListItem";
import useStyles from "./styles";

const copy = {
  eng: {
    title: "Documents Required",
    schoolTrancripts: "School Transcripts",
    schoolTranscriptsInfo: "These can show you that you came to the U.S. before the age 16 and have been in the U.S. continously.",
    proofOfIdentity: "Proof of identity",
    proofOfIdentityInfo: "Passport or national identity document from your country of origin",
    otherDocuments: "Other Documents",
    otherDocumentsInfo: ["U.S. official medical records", "Expired Visa", "Rent receipts or utility bills ", "Dated bank transactions", "Official records from the school that you are currently attending in the United States or high school diploma"],
  },
};

const Documents = () => {
  const classes = useStyles();

  return (
    <div>
      <Typography className={classes.subTitle}>Documents Required</Typography>
      <Typography className={classes.title3}>School Transcripts</Typography>
      <Typography className={classes.docBody}>These can show you that you came to the U.S. before the age 16 and have been in the U.S. continously.</Typography>
      <Typography className={classes.title3}>Proof of identity</Typography>
      <Typography className={classes.docBody}>passport or national identity document from your country of origin </Typography>
      <Typography className={classes.title3}>Other Documents</Typography>
      <List>
        {copy.eng.otherDocumentsInfo.map((document, index) => (
          <ListItemz key={`${index}`} item={document} />
        ))}
      </List>
    </div>
  );
};

export default Documents;
