import React from "react";
import LabelImportantIcon from "@material-ui/icons/LabelImportant";
import { Typography, ListItemIcon, ListItemText, ListItem } from "@material-ui/core";
import useStyles from "./styles";

const ListItemz = ({ item }) => {
  const classes = useStyles();
  return (
    <div>
      <ListItem>
        <ListItemIcon>
          <LabelImportantIcon />
        </ListItemIcon>
        <ListItemText
          primary={
            <div>
              <Typography className={classes.bullets}>{item}</Typography>
            </div>
          }
        />
      </ListItem>
    </div>
  );
};

export default ListItemz;
