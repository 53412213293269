import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import { useTranslations } from "../TranslationContext/TranslationContext";
import backgroundImage from "../../Images/back-pattern.png";

const useStyles = makeStyles(() => ({
  header: {
    backgroundColor: "#553a62",
    backgroundImage: `url(${backgroundImage})`,
    paddingTop: "125px",
  },
  title: {
    fontFamily: `'Shippori Mincho B1', serif`,
    color: "#fff",
    fontSize: "4rem",
  },
}));

const Header = ({ copy }) => {
  const classes = useStyles();
  const { currentLanguage } = useTranslations();

  return (
    <div className={classes.header}>
      <Typography align="center" className={classes.title}>
        {currentLanguage === "eng" ? copy.title : copy.titleSpn}
      </Typography>
    </div>
  );
};

export default Header;
