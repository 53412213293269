import React from "react";
import { Typography, Avatar, Grid } from "@material-ui/core";
import useStyles from "./styles";
import { useTranslations } from "../TranslationContext/TranslationContext";

const Review = ({ review }) => {
  let classes = useStyles();
  let { currentLanguage } = useTranslations();

  return (
    <div>
      <Grid container>
        <Grid item>
          <Typography className={classes.review} align="center">
            {currentLanguage === "eng" ? review.review : review.reviewSpn}
          </Typography>
        </Grid>
      </Grid>
      <div>
        <Grid container align="center" justify="center" alignItems="center" spacing={2}>
          <Grid item>
            <Avatar align="center" className={`${classes.purple} + ${classes.avatar}`}>
              {review.initials}
            </Avatar>
          </Grid>
          <Grid item>
            <Typography>{review.name}</Typography>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default Review;
