import { makeStyles } from '@material-ui/core/styles';
import backPattern from '../../Images/back-pattern.png'

export default makeStyles((theme) => ({

  root: {
    marginTop: "100px",
    backgroundColor: "#553a62",
    backgroundImage: `url(${backPattern})`,
  },
  Link: {
      textDecoration: 'none'
  
  },
  infoText: {
    textDecoration: 'none',
    color: "white",
    fontSize: "1.5rem",
    marginTop: "20px",
    padding: "10px",
    marginBottom: "20px",
  },
  outline: {
    [theme.breakpoints.up("md")]: {
      width: "20vw",
      margin: "60px auto",
      outline: "5px solid #553a62",
    },
    cursor: "pointer",
    border: "1px solid white",
    margin: "7px 10px",
    backgroundColor: "#553a62",
  },
  arrow: {
    color: "white",
    position: "relative",
    zIndex: "30",
    right: "10px",
  },
}))