import React from "react";
import { Typography, Grid } from "@material-ui/core";
import { useTranslations } from "../TranslationContext/TranslationContext";
import { makeStyles } from "@material-ui/core/styles";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import PhoneForwardedIcon from "@material-ui/icons/PhoneForwarded";

const copy = {
  eng: {
    title2: "Schedule your consultation today!",
    title3: "We often find a legal avenue for legalization that our clients did not know was possible",
  },
  spn: {
    title2: "¡Programe su consulta hoy!",
    title3: "Encontramos una vía legal para la legalización que nuestros clientes no sabían que era posible.",
  },
};

const useStyles = makeStyles(() => ({
  button: {
    marginRight: "10px",
    color: "#553a62",
  },
  title2: {
    fontFamily: `'Shippori Mincho B1', serif`,
    fontWeight: "700",
    marginBottom: "20px",
  },
  title3: {
    fontFamily: `'Montserrat', sans-serif;`,
    marginBottom: "20px",
    fontSize: "1rem",
    margin: "0 30px",
    fontWeight: "700",
    textDecoration: "underline",
  },
  phone: {
    fontFamily: `'Montserrat', sans-serif;`,
    fontWeight: "800",
    fontSize: "1rem",
  },
  root: {
    paddingTop: "50px",
    paddingBottom: "50px",
  },
}));

const Consultation = () => {
  const { currentLanguage } = useTranslations();
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography align="center" variant="h4" className={classes.title2}>
        {currentLanguage === "eng" ? copy.eng.title2 : copy.spn.title2}
      </Typography>

      <Typography variant="body1" className={classes.title3}>
        {currentLanguage === "eng" ? copy.eng.title3 : copy.spn.title3}
      </Typography>

      <Grid container align="center" justify="center" alignItems="center">
        <Grid item>
          <PhoneForwardedIcon className={classes.button} />
        </Grid>
        <Grid item>
          <Typography variant="h5" className={classes.phone}>
            (303) 455-1809
          </Typography>
        </Grid>
      </Grid>
      <Grid container align="center" justify="center" alignItems="center">
        <Grid item>
          <MailOutlineIcon className={classes.button} />
        </Grid>
        <Grid item>
          <Typography className={classes.phone}>info@jimenezimmigrationlaw.com </Typography>
        </Grid>
      </Grid>
    </div>
  );
};

export default Consultation;
