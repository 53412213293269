import React from "react";
import Header from "../Misc/Header";
import Footer from "../Footer/Footer";
import useStyles from "./styles";
import { Typography, Paper, Grid, List } from "@material-ui/core";
import Consultation from "../Misc/Consultation";
import ListItemz from "../Dreamers/ListItem";
import copy from "./copy";
import ArturoImg from "../Team/images/arturoR.jpg";

const title = {
  title: "About Arturo Jimenéz",
  titleSpn: "Sobre Arturo Jimenéz",
};

const Arturo = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Header copy={title} />
      <Grid container spacing={3} align="center" justify="center" alignItems="flex-start" className={classes.grid}>
        <Grid item md={6}>
          <Paper className={classes.paper1}>
            <Typography className={classes.mainTitle}>Arturo Jimenéz</Typography>
            <img src={ArturoImg} alt="arturoImage" className={classes.image} />
            <Typography align="left" className={classes.docBody}>
              {copy.eng.part1}
            </Typography>
            <Typography align="left" className={classes.docBody}>
              {copy.eng.part2}
            </Typography>
            <Typography align="left" className={classes.docBody}>
              {copy.eng.part3}
            </Typography>
            <Typography align="left" className={classes.subTitle}>
              {copy.eng.part4}
            </Typography>
            <List>
              {copy.eng.awards.map((award) => (
                <ListItemz item={award} />
              ))}
            </List>
          </Paper>
        </Grid>
        {/* Consultation Half */}
        <Grid item md={4}>
          <Paper>
            <Consultation />
          </Paper>
        </Grid>
      </Grid>
      <Footer />
    </div>
  );
};

export default Arturo;
