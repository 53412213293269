const services = [
    {
        id: 'service0',
        service: 'Spouse and Family Immigration Petitions',
        serviceSpn: 'Peticiones de inmigración para cónyuges y familiares',
        serviceHelp: 'How you may petition for relatives (or future relatives such as a fiancé(e) or a prospective adopted child) to immigrate to the United States. ',
        serviceHelpSpn: 'Cómo puede solicitar que sus parientes (o futuros parientes, como un prometido (a) o un posible hijo adoptado) inmigren a los Estados Unidos.',
    },
    {
        id: 'service1',
        service: 'Adjustment of Status/Legal Permanent Residence',
        serviceSpn: 'Ajuste de estatus/Residencia Legal Permanente',
        serviceHelp: 'Lawful permanent resident (LPR) status confers several significant privileges, rights, and responsibilities[1] that invoke a commitment to greater assimilation in the United States and offers a pathway to U.S. citizenship. ',
        serviceHelpSpn: 'El estatus de residente permanente legal (LPR) confiere varios privilegios, derechos y responsabilidades importantes [1] que invocan un compromiso con una mayor asimilación en los Estados Unidos y ofrecen un camino hacia la ciudadanía estadounidense.',
    },
    {
        id: 'service2',
        service: 'U visas for victims of violent crimes',
        serviceSpn: 'Visas U para víctimas de delitos violentos',
        serviceHelp: 'If you are an alien and are a victim of a qualifying criminal activity, use this form to petition for temporary immigration benefits for yourself and your qualifying family members, as appropriate.',
        serviceHelpSpn: 'Si usted es un extranjero y es víctima de una actividad delictiva calificada, use este formulario para solicitar beneficios de inmigración temporal para usted y los miembros de su familia calificados, según corresponda.',
    }, 
    {
        id: 'service3',
        service: 'Naturalization for Residents to become a Citizens',
        serviceSpn: 'Naturalización para que los residentes se conviertan en ciudadanos',
        serviceHelp: 'Preparation, forms and everything needed to become a citizen.',
        serviceHelpSpn: 'Preparación, formularios y todo lo necesario para convertirse en un Ciudadano.',
    },
    {
        id: 'service4',
        service: 'DACA and DACA Renewals',
        serviceSpn: 'Renovaciones DACA y DACA',
        serviceHelp: 'On June 15, 2012, the secretary of Homeland Security announced that certain people who came to the United States as children and meet several guidelines may request consideration of deferred action for a period of two years, subject to renewal.',
        serviceHelpSpn: 'El 15 de junio de 2012, el secretario de Seguridad Nacional anunció que ciertas personas que llegaron a los Estados Unidos cuando eran niños y cumplen con varias pautas pueden solicitar la consideración de la acción diferida por un período de dos años, sujeto a renovación.',
    },
    {
        id: 'service5',
        service: 'Green card Renewals',
        serviceSpn: 'Green card Renovaciones',
        serviceHelp: 'Having a Green Card (officially known as a Permanent Resident Card) allows you to live and work permanently in the United States. The steps you must take to apply for a Green Card will vary depending on your individual situation.',
        serviceHelpSpn: 'Tener una Green Card (oficialmente conocida como Tarjeta de Residente Permanente) le permite vivir y trabajar permanentemente en los Estados Unidos. Los pasos que debe seguir para solicitar una Green Card variarán según su situación individual.',
    },
    {
        id: 'service6',
        service: 'Work Permits',
        serviceSpn: 'Permiso de trabajo',
        serviceHelp: 'Certain aliens who are in the United States may file Form I-765, Application for Employment Authorization, to request employment authorization and an Employment Authorization Document (EAD)',
        serviceHelpSpn: 'Ciertos extranjeros que se encuentran en los Estados Unidos pueden presentar el Formulario I-765, Solicitud de autorización de empleo, para solicitar una autorización de empleo y un Documento de autorización de empleo (EAD)',
    },
    {
        id: 'service7',
        service: 'Special Immigrant Juvenile Status',
        serviceSpn: 'Estado Especial de Inmigrante Juvenil',
        serviceHelp: 'Juvenile  who needs the protection of a juvenile court because they have been abused, neglected or abandoned by a parent',
        serviceHelpSpn: 'Menor que necesita la protección de un tribunal de menores porque ha sido abusado, descuidado o abandonado por un padre',
    },
    {
        id: 'service8',
        service: 'VAWA petitions',
        serviceSpn: 'Peticiones VAWA',
        serviceHelp: 'A self-petitioning spouse or child of an abusive U.S. citizen or lawful permanent resident under VAWA',
        serviceHelpSpn: 'Un cónyuge o hijo auto-peticionario de un ciudadano estadounidense abusivo o residente permanente legal bajo VAWA',
    }

];

export default services;