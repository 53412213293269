import React from "react";
import Header from "../Misc/Header";
import Footer from "../Footer/Footer";
import Documents from "./Document";
import { Typography, List, Grid, Paper } from "@material-ui/core";
import Consultation from "../Misc/Consultation";
import ListItemz from "./ListItem";
import copy from "./copy";
import useStyles from "./styles";

const title = {
  title: "For Dreamers",
  titleSpn: "Para Los Dreamers",
};

const Dreamers = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Header copy={title} />
      <Grid container spacing={3} align="center" justify="center" alignItems="flex-start" className={classes.grid}>
        <Grid item md={6} align="left">
          <Paper className={classes.paper1}>
            <Typography align="center" className={classes.mainTitle}>
              Deferred Action for Childhood Arrivals(DACA)
            </Typography>
            <Typography className={classes.docBody}>*Initial applications are being received but are not currently being processed by USCIS until later ruling decides the case.*</Typography>
            <Typography className={classes.subTitle}>Requirements to Apply</Typography>
            <List>
              {copy.eng.req.map((item, index) => (
                <ListItemz key={`${index}`} item={item} />
              ))}
            </List>
            <Documents />
            <Typography className={classes.subTitle}>Renewals</Typography>
            <Typography className={classes.docBody}>A DACA recipient should send their renewal application at least 5 months before their DACA Expires.</Typography>
            <Typography className={classes.subTitle}>Advance Parole Travel</Typography>
            <Typography className={classes.docBody}>DACA recipients have the opportunity to travel abroad for humanitarian and educational reasons. For more information please contact us.</Typography>
          </Paper>
        </Grid>
        {/* Consultation Half */}
        <Grid item md={4}>
          <Paper>
            <Consultation />
          </Paper>
        </Grid>
      </Grid>
      <Footer />
    </div>
  );
};

export default Dreamers;
