import React, { useState } from "react";
import { Button, Grid, TextField, Typography, Container, Modal, Fade } from "@material-ui/core";
import SendIcon from "@material-ui/icons/Send";
import emailjs from "emailjs-com";
import { makeStyles } from "@material-ui/core/styles";
import validator from "email-validator";
import { useTranslations } from "../TranslationContext/TranslationContext";

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  modalText: {
    fontSize: "2rem",
    fontFamily: `'Shippori Mincho B1', serif`,
    margin: "20px 50px",
  },
  header: {
    fontSize: "3rem",
    fontFamily: `'Shippori Mincho B1', serif`,
    paddingBottom: "30px",
  },
}));

const copy = {
  eng: {
    title: "Schedule Your Consultation",
    email: "enter your email",
    subject: "subject",
    textBox: "How can we help you?",
    name: "name",
  },
  spn: {
    title: "Programe Su Consulta",
    email: "Tu correo electrónico",
    subject: "Sujeto",
    textBox: "como podemos ayudarte?",
    name: "nombre",
  },
};
const Contact = () => {
  const classes = useStyles();
  const { REACT_APP_EMAIL_API_KEY, REACT_APP_EMAIL_TEMPLATE, REACT_APP_SERVICE_ID } = process.env;
  const [open, setOpen] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const [validEmail, setValidEmail] = useState(true);

  const { currentLanguage } = useTranslations();

  const [email, setEmail] = useState({
    userEmail: "",
    subject: "",
    message: "",
    name: "",
  });

  const onValueChange = (e) => {
    const { value, id } = e.target;
    if (id === "userEmail") {
      setValidEmail(validator.validate(email.userEmail));
    }
    setEmail((prev) => {
      return {
        ...prev,
        [id]: value,
      };
    });
  };

  const sent = () => {
    setOpen(true);
    setEmailSent(true);
    setEmail({
      userEmail: "",
      subject: "",
      message: "",
      name: "",
    });
  };

  const handleClose = () => {
    setOpen(false);
    setEmailSent(false);
  };

  const onSend = () => {
    let emailSend = {
      email: email.userEmail,
      subject: email.subject,
      message: email.message,
      name: email.name,
    };

    emailjs.send(REACT_APP_SERVICE_ID, REACT_APP_EMAIL_TEMPLATE, emailSend, REACT_APP_EMAIL_API_KEY).then(
      (result) => {
        sent();
      },
      (error) => {
        console.log(REACT_APP_SERVICE_ID);
        console.log(error);
      }
    );
  };

  return (
    <div>
      <Container>
        <Typography className={classes.header}>{currentLanguage === "eng" ? copy.eng.title : copy.spn.title}</Typography>
        <Grid container spacing={3}>
          <Grid item>
            <TextField required id="name" label={currentLanguage === "eng" ? copy.eng.name : copy.spn.name} variant="filled" onChange={onValueChange} value={email.name} />
          </Grid>
          <Grid item>
            <TextField required error={!validEmail} id="userEmail" label={currentLanguage === "eng" ? copy.eng.email : copy.spn.email} onChange={onValueChange} value={email.userEmail} variant="filled" />
          </Grid>
          <Grid item>
            <TextField required id="subject" label={currentLanguage === "eng" ? copy.eng.subject : copy.spn.subject} variant="filled" onChange={onValueChange} value={email.subject} />
          </Grid>

          <Grid item xs={12}>
            <TextField required multiline fullWidth id="message" label={currentLanguage === "eng" ? copy.eng.textBox : copy.spn.textBox} variant="filled" value={email.message} onChange={onValueChange} rows={4} />
          </Grid>
          <Grid item>
            <Button disabled={!(email.userEmail && email.subject && email.message && validEmail && email.name)} variant="contained" onClick={onSend} size="large" endIcon={<SendIcon />}>
              Send
            </Button>
          </Grid>
        </Grid>
        <Modal open={open} onClose={handleClose} aria-labelledby="emailSent" aria-describedby="Email has been sent">
          <Fade in={open}>
            <div className={classes.paper}>
              <Typography align="center" className={classes.modalText}>
                {emailSent ? "Your Message has been sent. Our Office will reply as soon as possible." : "There was an error. Please try again later"}
              </Typography>
            </div>
          </Fade>
        </Modal>
      </Container>
    </div>
  );
};

export default Contact;
