import React from "react";
import { Grid, Box, Typography } from "@material-ui/core";
import Arrow from "@material-ui/icons/ArrowForwardIos";
import { Link } from "react-router-dom";

import useStyles from "./styles";

import { useTranslations } from "../TranslationContext/TranslationContext";

const InfoLink = ({ to, spanishLabel, englishLabel }) => {
  const classes = useStyles();
  const { currentLanguage } = useTranslations();

  return (
    <div>
      <Link to={to} className={classes.Link}>
        <Box boxShadow={24} className={classes.outline}>
          <Grid container alignItems="center" justify="space-between">
            <Grid item>
              <Typography className={classes.infoText}>{currentLanguage === "eng" ? englishLabel : spanishLabel}</Typography>
            </Grid>
            <Grid item>
              <Arrow className={classes.arrow} />
            </Grid>
          </Grid>
        </Box>
      </Link>
    </div>
  );
};

export default InfoLink;
