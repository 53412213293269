const copy = {
    eng: {
        part1: "The politicians failed the DREAM'ers and DACA recipients; they are living a nightmare. One knock on the door away from deportation",
        part2: "Our southern borders are crowded by thousands of families and children fleeing violence, corruption, kidnapping, rape, rampant crime, human trafficking, and human rights abuses that few of us can imagine.",
        part3: "Arturo Jiménez, an experienced family Immigration Lawyer, humanizes the issue, utilizing stories of his clients to help us understand the causes and effects of migration of LatinX children and families from the Violent Northern Triangle of El Salvador, Guatemala, and Honduras, and the surrounding states of Nicaragua and Mexico to the United States.",
        part4: "This book looks at the effects of American Foreign Policy and U.S. National Interests in Latin American and the associated human rights abuses which cause the migration of asylum seekers to the U.S. Souther border.",
        part5: "Arturo takes us on a personal journey to explore how the U.S. treats migrant asylum seekers, DREAM'ers, and DACA recipients. Arturo searches for the truth without religious or political bias sharing his firsthand experiences, insights, knowledge, and findings to help us come up with solutions, to start the consensual discussion of Immigration reform.",
        part6: "The immigration issue will shock you, have you in tears, of anger and sorrow. Whether you are a Democrat, Republican or Independent the DREAM'ers Nighmare: THE U.S. WAR ON IMMIGRANT LATINX CHILDREN will shatter your beliefs, enlightening you with the knowledge to share with others so together we can create beneficial change for all of our families.",
        part7: `If you think you are an expert on immigration, take the "Immigration Quiz"`,

    },
    spn: {
        part1: `Los políticos les fallaron a los DREAM'ers y a los destinatarios de DACA; están viviendo una pesadilla. Un golpe en la puerta lejos de la deportación`,
        part2: `Nuestras fronteras del sur están invadidas por solicitantes de asilo; migrantes que huyen de la violencia, la corrupción, el secuestro, la violación, la delincuencia desenfrenada, la trata de personas y los abusos de los derechos humanos que pocos de nosotros podemos imaginar, y mucho menos sobrevivir`,
        part3: `Arturo Jiménez, un experimentado abogado de inmigración familiar, humaniza el problema, utilizando historias de sus clientes para ayudarnos a comprender las causas y efectos de la migración de niños y familias latinx del Violento Triángulo Central de El Salvador, Guatemala y Honduras, y sus alrededores. estados de Nicaragua y México a los Estados Unidos.`,
        part4: `Este libro analiza los efectos de la política exterior estadounidense y los intereses nacionales de EE. UU. En América Latina y los abusos de derechos humanos asociados que provocan la migración de solicitantes de asilo a la frontera sur de EE. UU.`,
        part5: `Arturo nos lleva en un viaje personal para explorar cómo Estados Unidos trata a los solicitantes de asilo migrantes, los DREAM'ers y los destinatarios de DACA. Arturo busca la verdad sin prejuicios religiosos o políticos compartiendo sus experiencias, percepciones, conocimientos y hallazgos de primera mano para ayudarnos a encontrar soluciones, para iniciar la discusión consensuada sobre la reforma migratoria.`,
        part6: `El tema de la inmigración lo conmocionará, lo hará llorar, de ira y dolor. Ya sea que sea demócrata o republicano, la pesadilla de los DREAM'ers: LA GUERRA DE LOS EE. UU. CONTRA LOS NIÑOS LATINOS INMIGRANTES romperá sus creencias y lo iluminará con el conocimiento para compartir con otros para que juntos podamos hacer un cambio beneficioso.`,
        part7: `Si cree que es un experto en inmigración, realice el "Immigration Quix"`,
    }
}

export default copy;