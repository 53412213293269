import {makeStyles} from '@material-ui/core/styles'
import skyline from './images/denverSkyline.jpg'

export default makeStyles((theme) =>({
    home: {
        minHeight: '100vh',
        backgroundImage:`url(${skyline})`,
        backgroundRepeat:'no-repeat',
        backgroundSize: 'cover',
    },
    divTitle: {
        textAlign: 'center',
        position: 'absolute',
        paddingBottom: '25px',
        top: '50%',
        left: '50%',
        borderStyle: 'groove', 
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        transform: 'translate(-50%, -50%)',
        color: 'white',
    },
    mainText: {
        fontFamily: `'Shippori Mincho B1', serif`,
    },
    secondaryText: {
        fontFamily: `'Montserrat', sans-serif;`,
    },
    buttons: {
        marginTop: '30px',
        color: 'white',
        fontFamily: `'Montserrat', sans-serif;`,
        marginRight: '10px',
        fontWeight: '700',
        backgroundColor: '#553a62',
        marginBottom: '20px',
        '&:hover': {
            backgroundColor: 'rgba(255, 153, 0, 8)'
        }
    },
    link: {
        textDecoration: 'none',
    },
    icon: {
        marginRight: '5px'
    },
    butterfly: {
        position: 'absolute',
        backgroundColor: 'rgba(0, 0, 0, 0.50)',
        top: '80%',
        left: '70%',
        borderRadius: '20px',
        '&:hover': {
            color:'white',
            backgroundColor: 'rgba(236, 236, 236, 0.10)',
            cursor: 'pointer',
        }


    }

})); 