import {makeStyles} from '@material-ui/core/styles';
import banner from './images/BookBanner.jpg'; 

export default makeStyles((theme) => ({
    divImg: {
        backgroundColor: 'black',
        paddingTop: '100px',

    },
    largeLogo: {
        width: '25vw',
    },
    divOfImg: {
        backgroundColor: 'black',
        backgroundSize: 'cover'

    },
    bookImg: {
        width: '100%',
        height: 'auto'
    },
    titleText: {
        color: 'white',
        fontFamily: `'Shippori Mincho B1', serif`,
        fontWeight: '900',
        fontSize: '3rem'
    },
    subtitle: {
        color: 'white',
        fontFamily: `'Montserrat', sans-serif;`,
        fontWeight: '500',
        fontSize: '2rem'
    },
    page: {
        marginTop: '100px',
        marginBottom: '100px',
        padding: '0 50px',
        cursor: 'default',
    },
    root: {
        minHeight: '100vh',
        backgroundImage:`url(${banner})`,
        backgroundRepeat:'no-repeat',
        backgroundSize: 'cover',
    },
    answer: {
        fontFamily: `'Montserrat', sans-serif;`,
        fontWeight: '900',
        paddingTop: '7px',
        paddingBottom: '7px',
        '&:hover': {
            color: 'white',
            backgroundColor: 'black'
        }
    },
    question: {
        fontFamily: 'serif',
        fontWeight: '900',
        fontSize: '2rem',
        marginTop: '10px',
        marginBottom: '25px'

    },
    correctAnswer: {
        fontFamily: `'Montserrat', sans-serif;`,
        fontWeight: '900',
        backgroundColor: 'green',
        color: 'white',
    },
    wrongAnswer: {
        fontFamily: `'Montserrat', sans-serif;`,
        fontWeight: '900',
        backgroundColor: '#911313',
        color: 'white',
    },
    explain: {
        fontFamily: `'Montserrat', sans-serif;`,
        fontWeight: '900',
        paddingTop: '20px',
        paddingBottom: '20px'

    },
    image: {
        paddingBottom: '50px',
    },
    author: {
        fontFamily: `'Shippori Mincho B1', serif`,
        fontWeight: '900',
        fontSize: '3rem'
    },
    body: {
        fontFamily: `'Montserrat', sans-serif;`,
        fontSize: '1.25rem',
        textIndent: '50px',
        paddingBottom: '50px',
        textDecoration: 'none'
    },
    test: {
        fontSize:'1.5rem',
        fontFamily: `'Montserrat', sans-serif;`,
        fontWeight: '900',
        textDecoration: 'underline',
        paddingBottom: '25px',

    },
    synop: {
        paddingTop: '25px',

    },
    button: {
        color: 'white',
        fontFamily: `'Montserrat', sans-serif;`,
        fontWeight: '700',
        backgroundColor: '#553a62',
        marginBottom: '50px',
        '&:hover': {
            backgroundColor: 'rgba(255, 153, 0, 8)'
        }
    },
    testButton: {
        marginTop: '50px'
    },
    modal: {
        position: "absolute",
        boxShadow: theme.shadows[5],
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
    },
    result:  {
        fontFamily: `'Montserrat', sans-serif;`,
        fontSize: '2rem',
        fontWeight: '900',
        margin: '10px 20px'
    },
    resultBody: {
        fontFamily: `'Shippori Mincho B1', serif`,
        fontSize: '1.5rem',
        margin: '10px 20px',
        


    }
 
}))