export const familyList = [
	{
		title: 'Marriage',
		body: `Marriage makes it possible to provide legal status to a fiance or spouse. This is largely dependent on many factors including where each person is. The application process can lead to a Green Card or a special type of Visa including the Fiance visa. 
		Services are provided to help any family by explaining and guiding them through the process.`,
		titleSpn: 'Matrimonio',
		bodySpn: `El matrimonio permite otorgar estatus legal a un prometido o cónyuge. Esto depende en gran medida de muchos factores, incluido el lugar donde se encuentra cada persona. El proceso de solicitud puede conducir a una tarjeta verde o un tipo especial de visa, incluida la visa de prometido.
			Se brindan servicios para ayudar a cualquier familia explicándoles y guiándolos a través del proceso.`,
	},
	{
		title: 'Children',
		body: `USCIS makes it possible to include children in the petition for family status by US Citizens and some Legal Residents. The factors for this petition largely include their age and whether they are married or unmarried children. Services are provided to explain with clarity what are 
			the possibilites to maintain the family united.`,
		titleSpn: 'Hij@s',
		bodySpn: `USCIS hace posible la inclusión de hij@s en la petición de estatus legal. Los factores de esta petición incluyen en gran medida su edad y si son hijos casados ​​o solteros. Se brindan servicios para explicar con claridad qué son
		las posibilidades de mantener unida a la familia.`
	},
	{
		title: 'Parents',
		titleSpn: 'Padres',
		body: `It is possible to file a petition for a parent when a U.S. citizen turns 21 or older. Please call now to make your consultation with our Lawyer to find out more about what can be done.`,
		bodySpn: `Es posible presentar una petición para un padre cuando un ciudadano estadounidense cumple 21 años. Llame ahora para hacer su consulta con nuestro abogado y obtener más información sobre lo que se puede hacer.`
	},
	{
		title: 'Siblings',
		titleSpn: 'Herman@s',
		body: `A U.S. citizen who is 21 or older has the ability to apply for a sibling to live in the U.S. This process can be done through the Immigration Law Offices of Arturo Jiménez.`,
		bodySpn: `El ciudadano estadounidense que tiene 21 años o más tiene la capacidad de traer a un hermano a vivir en los Estados Unidos. Este proceso se puede realizar a través de las Oficinas Legales de Inmigración de Arturo Jiménez.`
	}

]