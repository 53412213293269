import React from "react";
import { Typography, Divider } from "@material-ui/core";
import useStyles from "./styles";
import { useTranslations } from "../TranslationContext/TranslationContext";

const UpdateCard = ({ info }) => {
  const classes = useStyles();
  const { currentLanguage } = useTranslations();
  return (
    <>
      <div className={classes.updateRoot}>
        <Typography className={classes.subTitle}>{currentLanguage === "eng" ? info.title : info.titleSpn}</Typography>
        <Typography className={classes.docBody}>{currentLanguage === "eng" ? info.info : info.infoSpn}</Typography>
      </div>
      <Divider />
    </>
  );
};

export default UpdateCard;
