import React, { useState } from "react";
import BookTest from "./BookTest";
import { Grid, Paper, Button, Typography } from "@material-ui/core";
import useStyles from "./styles";
import Footer from "../Footer/Footer";
import BookHeader from "./BookHeader";
import Synopsis from "./Synopsis";

const Book = () => {
  const classes = useStyles();

  const [doTakeQuiz, setDoTakeQuiz] = useState(false);

  const onButtonClick = () => {
    setDoTakeQuiz((prev) => !prev);
  };

  return (
    <div>
      <BookHeader />
      <Synopsis />
      <Grid container align="center" alignItems="center" justify="center">
        <Grid item sm={12} md={6}>
          <Paper className={classes.page}>
            <Typography className={classes.author}>Immigration Quiz</Typography>
            {doTakeQuiz ? (
              <BookTest />
            ) : (
              <Button className={`${classes.button}  ${classes.testButton}`} onClick={onButtonClick}>
                Take the quiz now!
              </Button>
            )}
          </Paper>
        </Grid>
      </Grid>
      <Footer />
    </div>
  );
};

export default Book;
