export const content = {
    eng : {
        lang: "eng",
        slogan: "ALWAYS UNITING FAMILIES",
        contactUs: "Contact Us For Your Consultation Today!",
        callUs: "Call US",
        messageUs: "Message US"
    },
    spn: {
        lang: "spn",
        slogan: "SIEMPRE UNIENDO FAMILIAS",
        contactUs: "Contacta nosotros para su consulta",
        callUs: "Llámanos",
        messageUs: "Envíanos un Mensaje"


    }
}