import React from "react";
import Header from "../Misc/Header";
import Footer from "../Footer/Footer";
import { Grid, Paper } from "@material-ui/core";
import Consultation from "../Misc/Consultation";
import Disclaimer from "../Misc/Disclaimer";
import useStyles from "./styles";
import { familyList } from "./FamilyList";
import FamilyCard from "./FamilyCard";

const title = {
  title: "For Families",
  titleSpn: "Para Familias",
};

const Families = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Header copy={title} />
      <Grid container spacing={3} align="center" justify="center" alignItems="flex-start" className={classes.grid}>
        <Grid item md={6} align="left">
          <Paper className={classes.paper1}>
            {familyList.map((item) => (
              <FamilyCard key={item.title} copy={item} />
            ))}
            <Disclaimer />
          </Paper>
        </Grid>

        {/* Consultation Half */}
        <Grid item md={4}>
          <Paper>
            <Consultation />
          </Paper>
        </Grid>
      </Grid>

      <Footer />
    </div>
  );
};

export default Families;
