import {makeStyles} from '@material-ui/core/styles';

export default makeStyles((theme) => ({
    title: {
        fontFamily: `'Shippori Mincho B1', serif`,
        textTransform: 'uppercase',
    },
    topContainer: {
        marginTop: '50px',
        marginBottom: '50px'
    },
    teamGrid: {
        width: '80%',
        margin: '0 auto',
        [theme.breakpoints.down('md')]: {
            width: '100%',
        },
    },
    root: {
        margin: "30px auto",
        maxWidth: '500px',
      },
      name: {
        fontFamily: `'Shippori Mincho B1', serif`,
        fontSize: '2.4rem',
      },
      position: {
        fontFamily: `'Montserrat', sans-serif;`,
      },
      description: {
          fontSize: '1rem'
      },
      button: {
          textDecoration: 'none'
      },
      Link: {
        textDecoration: 'none'
      }
}));
