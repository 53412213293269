import React, { useState } from "react";
import { Container, Grid, Typography, CssBaseline, Collapse } from "@material-ui/core";
import useStyles from "./styles";
import { items } from "./menuItems";
import MenuRoundedIcon from "@material-ui/icons/MenuRounded";
import CancelPresentationRoundedIcon from "@material-ui/icons/CancelPresentationRounded";
import logo from "./logo/ArturoLogo2021.svg";
import { Link } from "react-router-dom";

import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import LanguageButton from "./LanguageButton";

const Nav = () => {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const [scrollY, setScrollY] = useState(0);

  const [menuClicked, setMenuClicked] = useState(false);

  window.addEventListener("scroll", () => setScrollY(window.scrollY));

  return (
    <Container style={matches ? { backgroundColor: "rgba(0, 0, 0, .8)" } : { backgroundColor: `rgba(0,0,0, ${scrollY <= 200 ? scrollY * (0.8 / 200) : 0.8})` }} maxWidth={false} className={classes.navBar}>
      <CssBaseline />

      <Grid container alignItems="center" justify="space-around" align="center">
        <Grid item>
          <Grid container alignItems="center" align="center">
            <Grid item>
              <img src={logo} alt="logo" height={matches ? "50px" : `${scrollY <= 200 ? 150 - scrollY / 2 : 50}px`} />
            </Grid>
            <Grid item style={matches ? {} : { backgroundColor: `rgba(0, 0, 0, ${scrollY <= 200 ? 0.8 - scrollY * (0.8 / 200) : 0})`, borderRadius: "10px" }}>
              <Typography style={matches ? { fontSize: "2rem" } : { fontSize: `${scrollY <= 200 ? 3 - scrollY / 200 : 2}rem` }} className={classes.mainName}>
                Arturo Jiménez
              </Typography>
              <Typography style={matches ? { fontSize: "1rem" } : { fontSize: `${scrollY <= 200 ? 2 - scrollY / 200 : 1}rem` }} className={classes.subtitle}>
                Immigration Law
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        {/* MENU ICON */}
        {matches && (
          <Grid item xs={12}>
            {menuClicked ? <CancelPresentationRoundedIcon className={classes.icon} onClick={() => setMenuClicked((prev) => !prev)} /> : <MenuRoundedIcon className={classes.icon} onClick={() => setMenuClicked((prev) => !prev)} />}
          </Grid>
        )}
        {/* NAVIGATTION */}
        {!matches && (
          <Grid item>
            <Grid container className={classes.menuSection} alignItems="center" justify="flex-end" align="center" spacing={3}>
              {items.map((item, index) => (
                <Grid item key={item.title + index}>
                  <Link to={item.url} className={classes.navLink}>
                    {item.title}
                  </Link>
                </Grid>
              ))}
              <Grid item>
                <LanguageButton setMenuClicked={setMenuClicked} />
              </Grid>
            </Grid>
          </Grid>
        )}

        {matches && menuClicked && (
          <Collapse in={menuClicked}>
            <Grid container justify="center" align="center" alignItems="center">
              {items.map((item, index) => (
                <Grid key={item + index} item xs={12}>
                  <Link onClick={() => setMenuClicked((prev) => !prev)} to={item.url} className={classes.navLink}>
                    {item.title}
                  </Link>
                </Grid>
              ))}
              <Grid item>
                <LanguageButton setMenuClicked={setMenuClicked} />
              </Grid>
            </Grid>
          </Collapse>
        )}
      </Grid>
    </Container>
  );
};

export default Nav;
