const writtenTest = [
    {
        number: 1,
        question: 'Does the United States deport innocent children?',
        correctAnswer: 'Yes',
        answers: ['Yes', 'No'],
        explaination: 'Yes, our government deports unaccompanied children.',
    },
    {
        number: 2,
        question: 'What President deported more children than any other president in U.S. History?',
        correctAnswer: 'President Bill Clinton',
        answers: ['President Ronald Reagan', 'President George H.W. Bush', 'President Bill Clinton', 'President George W. Bush', 'President Barack Obama', 'President Donald Trump'],
        explaination: 'President Clinton 1993-2001 (D) Holds the record for total deportations 12,290,905',
    },
    {
        number: 3,
        question: 'Senators from what political party first introduced the DREAM Act into Congress?',
        correctAnswer: 'Republicans',
        answers: ['Republicans', 'Democrats', 'Green Party'],
        explaination: 'The Dream Act proposal was introduced as a bill by Republican Senator Orin Hatch of Utah and Democratic Senator Dick Durbin 2001',
    },
    {
        number: 4,
        question: 'How many times has the DREAM Act been introduced into at least one chamber of Congress?',
        correctAnswer: 'more than seven times',
        answers: ['2 times', '5 times', 'more than seven times'],
        explaination: 'Over the last 18 years, at least ten versions of the Dream Act have been introduced in Congress.',
    },
    {
        number: 5,
        question: 'Are DACA and the DREAM Act the same thing, or are they different?',
        correctAnswer: 'Different',
        answers: ['Same', 'Different'],
        explaination: 'DACA and the Dream Act are different things',
    },
    {
        number: 6,
        question: 'Does DACA lead to Permanent Legal Status or eventual Citizenship?',
        correctAnswer: 'Neither',
        answers: ['Permanent Legal Status', 'Eventual Citizenship', 'Neither'],
        explaination: 'The DACA program does not currently provide permanent lawful status or a path to citizenship',
    },
    {
        number: 7,
        question: `What is the most reacent military coup, takeover of a nation's government, in which the United States has acknowledged involvement?`,
        correctAnswer: 'Honduras',
        answers: ['Venezuela', 'Nicaragua','El Salvador', 'Honduras'],
        explaination: 'Honduras',
    },
    {
        number: 8,
        question: 'Which President last signed a law to expand the wall on our Southern border with Mexico?',
        correctAnswer: 'President Bill Clinton',
        answers: ['President Ronald Reagan', 'President George H.W. Bush', 'President Bill Clinton', 'President George W. Bush', 'President Barack Obama', 'President Donald Trump'],
        explaination: 'President Bill Clinton',
    },
    {
        number: 9,
        question: 'Are the children who have arrived at our Southern Border considered "Dreamers" by those sponsoring the DREAM ACT?',
        correctAnswer: 'No',
        answers: ['Yes', 'No'],
        explaination: 'No, the children who have arrived at our Southern Border are not vonsidred "Dreamers" by those sponsoring the DREAM ACT',
    },
    {
        number: 10,
        question: `Which is the last president to sign a law granting some level of 'amnesty' by offering Legal Permanent Residence and a pathway to citizenship to those who entered the U.S. unlawfully?`,
        correctAnswer: 'President Ronald Reagan',
        answers: ['President Ronald Reagan', 'President George H.W. Bush', 'President Bill Clinton', 'President George W. Bush', 'President Barack Obama', 'President Donald Trump'],
        explaination: 'The Immigration Reform and Control Act of 1986--signed into law by President Ronald Reagan on November 6, 1986--granted amnesty to about 3 million undocumented immigrants in the United States.',
    },
    {
        number: 11,
        question: 'Which political party controlled both the Senate, the House of Representatives and Presidency yet failed to pass legislation benefiting immigrants?',
        correctAnswer: 'Democratic Party',
        answers: ['Republican Party', 'Democratic Party', 'Other'],
        explaination: 'Democrats under President Barack Obama',
    },
    {
        number: 12,
        question: 'What are the three countries in the so-called Violent Nothern Triangle from which tens of thousands of children are arriving at our Souther Border?',
        correctAnswer: 'El Salvador, Guatemala and Hoduras',
        answers: ['El Salvador', 'Nicaragua', 'Guatemala', 'Honduras', 'All of the above', 'El Salvador, Guatemala and Hoduras'],
        explaination: `Central America's Violent Northern Triangle: El Salvador, Guatemala, and Honduras.`,
    },
    {
        number: 13,
        question: 'Do you believe that a wall on the Southern border might solve some our immigration problems?',
        correctAnswer: 'No',
        answers: ['Yes', 'No'],
        explaination: 'No',
    },
]

export default writtenTest;