import React from "react";
import { GridList, GridListTile } from "@material-ui/core";
import { tiles } from "./tiles";

import useStyles from "./styles";

const Photos = () => {
  const classes = useStyles();

  return (
    <div className={classes.gridRoot}>
      <GridList cellHeight={400} className={classes.gridList} cols={3}>
        {tiles.map((tile) => (
          <GridListTile key={tile.img} cols={tile.cols || 1}>
            <img src={tile.img} alt={tile.title} />
          </GridListTile>
        ))}
      </GridList>
    </div>
  );
};

export default Photos;
