import React, { useState } from "react";
import { Card, CardMedia, CardActionArea, CardActions, Button, Typography, CardContent, Divider } from "@material-ui/core";
import { useTranslations } from "../TranslationContext/TranslationContext";
import useStyles from "./styles";
import { Link } from "react-router-dom";

const TeamCard = ({ member }) => {
  const classes = useStyles();
  const [pressed, setPressed] = useState(false);
  const { currentLanguage } = useTranslations();
  const onCardPress = () => {
    setPressed((prev) => !prev);
  };

  return (
    <div>
      <Card raised={true} className={classes.root} onClick={() => onCardPress()}>
        <CardActionArea>
          <CardMedia component="img" alt={`${member.firstName}HeadShot`} height="400" image={member.photo} title={`${member.position}: ${member.firstName} ${member.lastName}`} />
          <CardContent>
            <Typography variant="h5" align="center" className={classes.name}>{`${member.firstName} ${member.lastName}`}</Typography>
            <Divider />
            <Typography className={classes.position} align="center">
              {currentLanguage === "eng" ? member.position : member.positionSpn}
            </Typography>
            <Typography className={classes.description} align="justify" variant="body2" color="textSecondary" component="p">
              {!pressed && (currentLanguage === "eng" ? member.description.substring(0, 200) + " ... READ MORE" : member.descriptionSpn.substring(0, 200) + " ... LEE MAS")}
              {pressed && (currentLanguage === "eng" ? member.description : member.descriptionSpn)}
            </Typography>
          </CardContent>
        </CardActionArea>
        {member.firstName === "Arturo" && (
          <CardActions>
            <Link to="/arturo-jimenez" className={classes.Link}>
              <Button variant="outlined" className={classes.button} size="large">
                {currentLanguage === "eng" ? "More About Arturo" : "Mas De Arturo"}
              </Button>
            </Link>
          </CardActions>
        )}
      </Card>
    </div>
  );
};

export default TeamCard;
