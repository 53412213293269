import { faPoll, faKey, faHeart, faHandshake, faGavel } from "@fortawesome/free-solid-svg-icons";

const stats = [
    {
        title: 'Families Served',
        titleSpn: 'Familias Atendidas',
        val:"2000+",
        icon: faHandshake
    },
    {
        title: 'Children Served (UAC)',
        titleSpn: 'Niños Servidos (UAC)',
        val: "100+",
        icon: faHeart,

    }, 
    {
        title: 'Immigrant Victims Served',
        titleSpn: 'Víctimas Inmigrantes Atendidos',
        val: "1000+",
        icon: faGavel,
    }, 
    {
        title: 'Immigration Presentations',
        titleSpn: 'Presentaciones de Inmigración',
        val: "100+",
        icon: faPoll,
    },
    {
        title: 'Naturalizations',
        titleSpn: 'Naturalizaciónes',
        val: "1500+",
        icon: faKey,
    },

]

export default stats;