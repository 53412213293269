import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Typography } from "@material-ui/core";
import useStyles from "./styles";
import { useTranslations } from "../TranslationContext/TranslationContext";

const Stat = ({ stat, index, checked }) => {
  const classes = useStyles();
  const { currentLanguage } = useTranslations();

  return (
    <div>
      <div className={classes.iconBox}>
        <FontAwesomeIcon icon={stat.icon} size="4x" className={classes.icon} />
      </div>
      <Typography className={classes.statInfo}>{currentLanguage === "eng" ? stat.title : stat.titleSpn}</Typography>
      <Typography className={classes.statNum}>{stat.val}</Typography>
    </div>
  );
};

export default Stat;
