import React from "react";
//import translationsData from "./strings.json";

export const TranslationContext = React.createContext({
  changeCurrentLanguage: (language) => null,
  currentLanguage: "",
});

export const TranslationProvider = ({ children }) => {
  const [currentLanguage, setCurrentLanguage] = React.useState("eng");

  const changeCurrentLanguage = React.useCallback(
    (newLanguage) => {
      if (currentLanguage !== newLanguage) {
        setCurrentLanguage(newLanguage);
      }
    },
    [currentLanguage, setCurrentLanguage]
  );
  return <TranslationContext.Provider value={{ changeCurrentLanguage, currentLanguage }}>{children}</TranslationContext.Provider>;
};

export const useTranslations = () => {
  return React.useContext(TranslationContext);
};
