const copy = {
    eng: {
        req: [
            "Came to the United States before the age 16",
            "Has Graduated HS or:",
            "Is going to school or",
            "Received a GED or",
            "Honorable discharged veteran.",
            "Is at least 15 years when requesting DACA",
            "Under the age 31 as of June 15, 2012",
            "Have not been convicted of a Felony, a driving under the influence offense or a significant misdemeanor(such as shoplifting, marijuana or Domestic Violence)",
            "Had no Lawful Status on June 15, 2012",
        ],
            



    }
}

export default copy;