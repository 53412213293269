import React from "react";
import { Grid } from "@material-ui/core";
import useStyles from "./styles";
import TeamCard from "./TeamCard";
import { team } from "./teamMembers";

const Team = () => {
  const classes = useStyles();
  return (
    <div>
      <Grid container spacing={2} justify="center" align="center" className={classes.teamGrid}>
        {team.map((member, index) => (
          <Grid item sm={12} md={member.width} key={`${member.firstName}_${index}`}>
            <TeamCard member={member} />
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default Team;
