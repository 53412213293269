import React from "react";
import { Button } from "@material-ui/core";
import { useTranslations } from "../TranslationContext/TranslationContext";
import useStyles from "./styles";

const LanguageButton = (props) => {
  const classes = useStyles();
  const { currentLanguage, changeCurrentLanguage } = useTranslations();

  const changeLang = () => {
    if (currentLanguage === "eng") {
      changeCurrentLanguage("spn");
    } else {
      changeCurrentLanguage("eng");
    }
    props.setMenuClicked((prev) => !prev);
  };

  return (
    <div>
      <Button className={classes.languageButton} variant="contained" onClick={() => changeLang()}>
        {currentLanguage === "eng" ? "Español" : "English"}
      </Button>
    </div>
  );
};

export default LanguageButton;
