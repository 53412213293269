import {makeStyles} from '@material-ui/core/styles';

export default makeStyles((theme) => ({
    title: {
        fontFamily:`'Shippori Mincho B1', serif`, 
        textTransform: 'uppercase',
        color: 'black',
        margin: '20px auto'
    },
    list: {
        width: '100%',
        fontFamily: `'Montserrat, sans-serif;'`,
        marginTop: '15px',
    },
    mainGrid: {
        backgroundColor: 'white',
        margin: '50px auto',
        borderRadius: '10px',
        maxWidth: '90%'


    },

    popover: {
        pointerEvents: 'none',
      },
    paper: {
        padding: theme.spacing(1),
        maxWidth: '50%'
      },
    listText: {
        fontFamily:`'Montserrat', sans-serif;`,
        backgroundColor: 'transparent',
        textTransform: 'uppercase',
        fontWeight: 'bold',
        margin: '10px',
        fontSize: '1rem',
        '&:hover': {
            color: '#553a62',
            cursor: 'default',

        },
    },
    listTitle: {
        fontFamily:`'Montserrat', sans-serif;`,
        textTransform: 'uppercase',
        margin: '20px',
        fontWeight: 'bold',
        textDecoration: 'underline',
        fontSize: '1.5rem',
    },
    button: {
        marginRight: '10px',
        color: '#553a62',
    },
    title2: {
        fontFamily:`'Shippori Mincho B1', serif`, 
        fontWeight: '700',
        marginBottom: '20px',
    },
    title3: {
        fontFamily:`'Montserrat', sans-serif;`,
        marginBottom:'20px',
        fontSize: '1rem',
        margin: '0 30px',
        fontWeight: '700',
        textDecoration: 'underline',
    },
    phone: {
        fontFamily:`'Montserrat', sans-serif;`,
        fontWeight: '800',
        fontSize: '1rem',
    },


}))
