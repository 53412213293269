import React from "react";
import { Typography, Grid, Paper, Button } from "@material-ui/core";
import Arturo from "./images/arturoR.jpg";
import useStyles from "./styles";
import copy from "./synopsisCopy";
import { useTranslations } from "../TranslationContext/TranslationContext";

const Synopsis = () => {
  const classes = useStyles();
  const { currentLanguage } = useTranslations();

  return (
    <div className={classes.synop}>
      <Grid container align="center" alignItems="center" justify="center">
        <Grid item sm={12} md={6}>
          <Paper>
            <Typography className={classes.author}>Arturo Jiménez</Typography>
            <img src={Arturo} alt="arturoImage" className={classes.image} />
            <Typography className={classes.author}>Book Synopsis</Typography>
            <Typography className={classes.body}>{currentLanguage === "eng" ? copy.eng.part1 : copy.spn.part1}</Typography>
            <Typography className={classes.body}>{currentLanguage === "eng" ? copy.eng.part2 : copy.spn.part2}</Typography>
            <Typography className={classes.body}>{currentLanguage === "eng" ? copy.eng.part3 : copy.spn.part3}</Typography>
            <Typography className={classes.body}>{currentLanguage === "eng" ? copy.eng.part4 : copy.spn.part4}</Typography>
            <Typography className={classes.body}>{currentLanguage === "eng" ? copy.eng.part5 : copy.spn.part5}</Typography>
            <Typography className={classes.body}>{currentLanguage === "eng" ? copy.eng.part6 : copy.spn.part6}</Typography>
            <Button size="large" className={classes.button} onClick={() => window.open("https://www.amazon.com/Arturo-Jim%C3%A9nez/e/B07Y4351T4/ref=dp_byline_cont_pop_book_1", "_blank")}>
              Buy Book
            </Button>
            <Typography className={classes.test}>{copy.eng.part7}</Typography>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default Synopsis;
