import React from "react";
import { Typography, Popover } from "@material-ui/core";
import useStyles from "./styles";
import { useTranslations } from "../TranslationContext/TranslationContext";

const ServiceCard = (props) => {
  const classes = useStyles();
  const service = props.service;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { currentLanguage } = useTranslations();

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <div key={service.id}>
      <Typography className={classes.listText} aria-owns={open ? "mouse-over-popover" : undefined} aria-haspopup="true" onMouseEnter={handlePopoverOpen} onMouseLeave={handlePopoverClose}>
        {currentLanguage === "eng" ? service.service : service.serviceSpn}
      </Typography>
      <Popover
        id="mouse-over-popover"
        className={classes.popover}
        classes={{
          paper: classes.paper,
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Typography>{currentLanguage === "eng" ? service.serviceHelp : service.serviceHelpSpn}</Typography>
      </Popover>
    </div>
  );
};

export default ServiceCard;
