import React, { useState } from "react";
import test from "./writtenTest";
import { LinearProgress, Grid, Paper, Button, Typography, Modal, Fade } from "@material-ui/core";
import useStyles from "./styles";

const initialUserAnswers = {
  1: "",
  2: "",
  3: "",
  4: "",
  5: "",
  6: "",
  7: "",
  8: "",
  9: "",
  10: "",
  11: "",
  12: "",
  13: "",
};

const NUMOFQS = 13;

const BookTest = () => {
  const classes = useStyles();

  const [numCorrectAnswers, setNumCorrectAnswers] = useState(0);
  const [questionNum, setQuestionNum] = useState(1);
  const [userAnswers, setUserAnswers] = useState(initialUserAnswers);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isSelected, setIsSelected] = useState(false);

  const correctAnswers = {};

  test.forEach((question) => {
    correctAnswers[question.number] = question.correctAnswer;
  });

  const onForward = () => {
    setIsSelected(false);
    if (questionNum === NUMOFQS) return;
    setQuestionNum((prev) => prev + 1);
  };

  const answerSelect = (event) => {
    setIsSelected(true);
    setUserAnswers((prev) => {
      return {
        ...prev,
        [questionNum]: event.target.innerHTML,
      };
    });
  };
  const ace = "Great Job! You are familiar with these topics";
  const pass = "Well Done. There is opportunity for growth";
  const notPass = "You should try again!";

  const onSubmit = () => {
    setIsSubmitted(true);
    checkAnswers();
  };

  const checkAnswers = () => {
    let rightAnswers = 0;
    for (var key in correctAnswers) {
      if (correctAnswers[key] === userAnswers[key]) {
        rightAnswers++;
      }
    }
    setNumCorrectAnswers(rightAnswers);
  };

  const restart = () => {
    setUserAnswers(initialUserAnswers);
    setQuestionNum(1);
    setIsSelected(false);
    setIsSubmitted(false);
  };

  return (
    <div>
      <LinearProgress variant="determinate" value={(questionNum / NUMOFQS) * 100} />

      {!isSubmitted && (
        <div>
          {test.map((question, index) => (
            <div key={`${question.number}_${index}`} hidden={!(questionNum === question.number)}>
              <Typography className={classes.question}>{question.question}</Typography>

              {!isSelected ? (
                <div>
                  {question.answers.map((answer, index) => (
                    <Typography align="center" justify="center" className={classes.answer} key={`${answer}_${index}`} onClick={answerSelect}>
                      {answer}
                    </Typography>
                  ))}
                </div>
              ) : (
                <div>
                  <Typography className={userAnswers[questionNum] === correctAnswers[questionNum] ? classes.correctAnswer : classes.wrongAnswer}> {userAnswers[questionNum]}</Typography>
                  <Typography className={classes.explain}> Correct Answer: {question.explaination}</Typography>
                </div>
              )}
            </div>
          ))}

          {questionNum === NUMOFQS ? (
            <Button variant="contained" className={classes.button} visibility="hidden" disabled={userAnswers[questionNum] === ""} onClick={onSubmit}>
              Submit
            </Button>
          ) : (
            <Button variant="contained" className={classes.button} disabled={userAnswers[questionNum] === ""} onClick={onForward}>
              Forward
            </Button>
          )}
        </div>
      )}

      <Modal open={isSubmitted} onClose={restart}>
        <Fade in={isSubmitted}>
          <Paper className={classes.modal}>
            <Grid container alignItems="center" align="center" justify="center">
              <Grid item>
                <Typography className={classes.result}>You have received a {Number.parseFloat(numCorrectAnswers / NUMOFQS).toFixed(2) * 100}%</Typography>
                <Typography className={classes.resultBody}>{numCorrectAnswers >= 11 ? ace : numCorrectAnswers >= 7 ? pass : notPass}</Typography>
                <Typography className={classes.resultBody}>DREAM'ers Nightmare: The U.S. War On Immigrant LatinX Children provides an excellent oppurtunity to learn more about these questions!</Typography>
                <Button size="large" className={classes.button} onClick={() => window.open("https://www.amazon.com/Arturo-Jim%C3%A9nez/e/B07Y4351T4/ref=dp_byline_cont_pop_book_1", "_blank")}>
                  Buy Book
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Fade>
      </Modal>
    </div>
  );
};

export default BookTest;
