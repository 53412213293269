import React from "react";
import useStyles from "./styles";
import { Typography, Divider } from "@material-ui/core";
import { useTranslations } from "../TranslationContext/TranslationContext";

const FamilyCard = ({ copy }) => {
  const classes = useStyles();
  const { currentLanguage } = useTranslations();

  return (
    <>
      <div className={classes.cardRoot}>
        <Typography className={classes.subTitle}>{currentLanguage === "eng" ? copy.title : copy.titleSpn}</Typography>
        <Typography className={classes.docBody}>{currentLanguage === "eng" ? copy.body : copy.bodySpn}</Typography>
      </div>
      <Divider />
    </>
  );
};

export default FamilyCard;
