import React from "react";
import Nav from "./components/Navigation/Nav";
import HomeComponents from "./components/HomeComponents";
import Book from "./components/Book/Book";
import Dreamers from "./components/Dreamers/Dreamers";
import Updates from "./components/Updates/Updates";
import Arturo from "./components/Arturo/Arturo";
import Families from "./components/Families/Families";
import ScrollToTop from "./components/Misc/ScrollToTop";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { TranslationProvider } from "./components/TranslationContext/TranslationContext";
// Purple color : #553a62

const App = () => {
  return (
    <div>
      <TranslationProvider>
        <Router>
          <ScrollToTop />
          <Nav />
          <Switch>
            <Route path="/services" component={() => <HomeComponents section="services" />} />
            <Route path="/team" component={() => <HomeComponents section="team" />} />
            <Route path="/contact" component={() => <HomeComponents section="contact" />} />
            <Route path="/arturo-jimenez" component={Arturo} />
            <Route path="/families" component={Families} />
            <Route path="/home" component={HomeComponents} />
            <Route path="/news" component={Updates} />
            <Route path="/book" component={Book} />
            <Route path="/dreamers" component={Dreamers} />
            <Route path="/" component={HomeComponents} />
          </Switch>
        </Router>
      </TranslationProvider>
    </div>
  );
};

export default App;
