export const updatesData = [
    {
        title: 'Continue to Renew Your DACA',
        titleSpn: 'DACA Completamente Reinstalado',
        info: `DACA renewal is has been reinstated. Dreamers are also able to apply for Advance Parole which allows a dreamer to leave the country and legally come in under strict circumstaces. The federal courts are allowing immigration to accept new applications however government cannot process new applications until an appeals court decides on the case.`,
        infoSpn: 'DACA se restablece por completo y permite a los dreamers que son elegibles solicitar DACA por primera vez. Los dreamers también pueden solicitar la libertad condicional anticipada nuevamente, lo que permite al dreamers salir del país y entrar legalmente en circunstancias estrictas.',
    },
    // {
    //     title: 'USCIS Fee Increase',
    //     titleSpn: 'Aumento de tarifas de USCIS',
    //     info: 'The government has increased fees for Immigration Benefit Request these include: Petition for Fiancé, Adjust Status, Naturalizations and more.',
    //     infoSpn: 'El gobierno ha aumentado las tarifas para la Solicitud de beneficios de inmigración, que incluyen: Petición de prometido, Ajuste de estatus, Naturalizaciones y más .',
        
    // },
    {
        title: 'Public Charge Update',
        titleSpn: 'Actualización de carga pública',
        info: 'Immigrants who accept government help are still eligible to apply for immigration benefits. ',
        infoSpn: 'Los inmigrantes que aceptan ayuda del gobierno que incluye: SNAP, Meidicaid y la Sección 8 pueden tener problemas al solicitar cualquier beneficio de inmigración.',
        
    }
]