const copy = {
  eng: {
    part1:
      "Arturo Jiménez is a bilingual Immigration Attorney who has represented thousands of individuals for over two decades in Colorado. He graduated from the University of Colorado School of Law and is one of the founders of the Immigration Clinic at Catholic Charities in Pueblo, CO.",
    part2: "After serving as the President of the Colorado Statewide Parent Coalition, Arturo was twice-elected to the Board of Education of the Denver Public Schools from 2007-2015.",
    part3:
      "Arturo is a member of the Colorado Chapter of the American Immigration Lawyers Association, the National Immigration Project of the National Lawyers Guild, the Colorado and Denver Bar Associations and the National Association of Latino Elected Officials. He is also a current Board Member of the Colorado Latino Forum.",
    part4: "Awards",
    awards: [
      "2016 Lena Archuleta Award for Education Leadership by the Colorado Latino/Latina Advocacy and Research Organization (CLLARO)",
      "2016 Federico Pena Education Leadership Award by the Colorado Association of Bilingual Educators (CABE)",
      "The Reproductive Justice Champion Award from the Colorado Organization for Latina Opportunity and Reproductive Rights (COLOR) 2013",
      "The Education Leadership Champion Award from the Escuela Tlatelolco",
      "International Latino Book Award for Best Political/Current Affairs Book "
    ],
  },
};

export default copy;