const reviews = [
    {
        id: 'claudiaReview',
        review: `Mr. Jimenez is always professional and direct and always willing to answer any questions or concerns! Im always received with a smile and a great welcome! Very impressed on his professionalism! I admire his passion to help the spanish community. I highly recommend him and his staff`,
        reviewSpn: `¡El Sr. Jiménez es siempre profesional y directo y siempre está dispuesto a responder cualquier pregunta o inquietud! ¡Siempre me reciben con una sonrisa y una gran bienvenida! ¡Muy impresionado por su profesionalismo! Admiro su pasión por ayudar a la comunidad española. Lo recomiendo a él y a su personal.`,
        name: 'Claudia Simental',
        initials: 'CS',

    },
    {
        id: 'caitlinReview',
        review: `I attended a Know Your Rights workshop given by Arturo Jimenez yesterday and was so impressed. He did such a good job of explaining the complicated intricacies of immigration law in a way that everyone in the room could understand`,
        reviewSpn: `Ayer asistí a un taller Conoce tus derechos impartido por Arturo Jiménez y quedé muy impresionado. Hizo un buen trabajo al explicar las complicadas complejidades de la ley de inmigración de una manera que todos en la sala pudieran entender.`,
        name: 'Caitlin Mendenhall',
        initials: 'CM'

    },
    {
        id: 'lauriReview',
        review: `I have worked with Mr. Jiménez through the 32nd Avenue Jubilee Center. I am always amazed at his kindness and patience with clients as well as his efficiency and thoroughness. I am so grateful to have such a trustworthy immigration attorney in our neighborhood.`,
        reviewSpn: `He trabajado con el Sr. Jiménez a través del 32nd Avenue Jubilee Center. Siempre me sorprende su amabilidad y paciencia con los clientes, así como su eficiencia y minuciosidad. Estoy muy agradecido de tener un abogado de inmigración tan confiable en nuestro vecindario.`,
        name: 'Lauri Hornbuckle Briscoe',
        initials: 'LB'

    }
]

export default reviews;