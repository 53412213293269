import React, { useState } from "react";
import { Typography, CssBaseline, Button } from "@material-ui/core";
import useStyles from "./styles";
import CallIcon from "@material-ui/icons/Call";
import EmailIcon from "@material-ui/icons/Email";
import { content } from "./homeContent";
import { Link } from "react-router-dom";

import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import butterfly from "./images/monarch-butterfly.svg";
import { useTranslations } from "../TranslationContext/TranslationContext";

const Home = () => {
  const { currentLanguage } = useTranslations();
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const medMatch = useMediaQuery(theme.breakpoints.down("md"));
  const [call, setCall] = useState(false);

  const copy = currentLanguage === "eng" ? content.eng : content.spn;

  return (
    <div id="home" className={classes.home}>
      <CssBaseline />

      <div className={classes.divTitle}>
        <Typography name="slogan" className={classes.mainText} variant={matches ? "h5" : "h2"}>
          {copy.slogan}
        </Typography>
        <Typography name="contactUs" className={classes.secondaryText} variant={matches ? "h6" : "h4"}>
          {copy.contactUs}
        </Typography>
        <Button onClick={() => setCall((prev) => !prev)} className={classes.buttons} variant="contained">
          <CallIcon name="callUs" className={classes.icon} />
          {call ? "(303) 455-1809" : copy.callUs}
        </Button>
        <Link className={classes.link} to="/contact">
          <Button className={classes.buttons} variant="contained">
            <EmailIcon name="messageUs" className={classes.icon} />
            {copy.messageUs}
          </Button>
        </Link>
      </div>

      {/* Dreamers Section */}

      {!medMatch && (
        <Link to="/dreamers">
          <img className={classes.butterfly} src={butterfly} alt="butterfly" height="18%" />
        </Link>
      )}
    </div>
  );
};

export default Home;
