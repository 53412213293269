export const items = [
    {
        title:'Home',
        url:'/home',
        cName:'navLink'
    },

    {
        title:'Services',
        url:'/services',
        cName:'navLink'
    },
    {
        title:'Team',
        url:'/team',
        cName:'navLink'
    },
    // {
    //     title:'Updates',
    //     url:'/updates',
    //     cName:'navLink'
    // },
    {
        title:'Book',
        url:'/book',
        cName:'navLink'
    },
]