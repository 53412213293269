import photo1 from "./Images/reviews1.jpeg";
import photo2 from "./Images/reviews2.jpeg";
import photo3 from "./Images/reviews3.jpeg";
import photo4 from "./Images/reviews4.jpeg";
import photo5 from "./Images/reviews5.png";

export const tiles = [
    {
        img: photo4,
        cols:3,
        title: 'name4'
        
    },
    {
        img: photo1,
        cols:1,
        title: 'name1'
        
    },
    {
        img: photo2,
        cols:1,
        title: 'name2'
        
    },
    {
        img: photo3,
        cols:1,
        title: 'name3'
        
    },
    {
        img: photo5,
        cols:3,
        title: 'name5'
        
    },

]