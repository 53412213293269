import React, { useEffect } from "react";
import Home from "./WebHome/Home";
import Team from "./Team/Team";
import Services from "./Services/Services";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Divider, Grid, Paper } from "@material-ui/core";
import Footer from "./Footer/Footer";
import Contact from "./Contact/Contact";
import InfoBanner from "./InfoBanners/InfoBanner";
import AllReviews from "./Reviews/AllReviews";
import Stats from "./Stats/AllStats";
import { useTranslations } from "./TranslationContext/TranslationContext";

const copy = {
  eng: {
    team: "Our Team",
    team2: "Everyone on our team is Bilingual in English and in Spanish.",
    services: "Services",
    reviews: "Reviews",
    contact: "Contact Us",
  },
  spn: {
    team: "Nuestro Equipo",
    team2: "Todos en nuestro equipo son bilingües en inglés y en español.",
    services: "Servicios",
    reviews: "Reseñas",
    contact: "Envíanos un Mensaje",
  },
};

const useStyles = makeStyles(() => ({
  title: {
    fontFamily: `'Shippori Mincho B1', serif`,
    textTransform: "uppercase",
    paddingTop: "100px",
    color: "black",
    margin: "20px auto",
  },
  paper: {
    marginTop: "40px",
    marginBottom: "50px",
  },
  title2: {
    fontFamily: `'Shippori Mincho B1', serif`,
    textTransform: "uppercase",
    paddingTop: "50px",
    color: "black",
    margin: "20px auto",
  },
}));

const HomeComponents = ({ section }) => {
  const classes = useStyles();
  const { currentLanguage } = useTranslations();
  const sectionToRender = section;

  useEffect(() => {
    if (sectionToRender === "services") {
      window.location.replace("#services");
    } else if (sectionToRender === "team") {
      window.location.replace("#team");
    } else if (sectionToRender === "contact") {
      window.location.replace("#contact");
    } else {
      window.location.replace("#home");
    }
  }, [sectionToRender]);

  return (
    <div className={classes.root}>
      <Home id="home" />
      <InfoBanner />
      <Typography id="team" align="center" variant="h2" className={classes.title}>
        {currentLanguage === "eng" ? copy.eng.team : copy.spn.team}
      </Typography>
      <Divider />
      <Typography id="teamBili" align="center" variant="h4" className={classes.title2}>
        {currentLanguage === "eng" ? copy.eng.team2 : copy.spn.team2}
      </Typography>
      <Team />
      <Stats />
      <Typography id="services" align="center" variant="h2" className={classes.title}>
        {currentLanguage === "eng" ? copy.eng.services : copy.spn.services}
      </Typography>
      <Divider />
      <Services />
      <Typography id="reviews" align="center" variant="h2" className={classes.title}>
        {currentLanguage === "eng" ? copy.eng.reviews : copy.spn.reviews}
      </Typography>
      <Divider />
      <AllReviews />

      <Typography id="contact" align="center" variant="h2" className={classes.title}>
        {currentLanguage === "eng" ? copy.eng.contact : copy.spn.contact}
      </Typography>
      <Divider />
      <Grid container align="center" justify="center">
        <Grid item sm={12} md={6}>
          <Paper elevation={4} className={classes.paper}>
            <Contact />
          </Paper>
        </Grid>
      </Grid>
      <Footer />
    </div>
  );
};

export default HomeComponents;
