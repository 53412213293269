import React from "react";
import { Grid } from "@material-ui/core";
import useStyles from "./styles";
import InfoLink from "./InfoLink";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";

const InfoBanner = (props) => {
  const classes = useStyles();
  const theme = useTheme();

  const medMatch = useMediaQuery(theme.breakpoints.down("md"));

  const engCopy = ["For Families", "Latest News", "Read our Book", "For Dreamers"];
  const spnCopy = ["Para Familias", "Últimas Noticias", "Lee Nuestro Libro", "Para Dreamers"];

  //For Families
  //Latest News
  //Read Our Book

  return (
    <div>
      <Grid className={classes.root} container align="center" alignItems="center" justify="space-evenly">
        <Grid item xs={12} md={3}>
          <InfoLink to="/families" spanishLabel={spnCopy[0]} englishLabel={engCopy[0]} activeWhenExact={true} />
        </Grid>
        {medMatch && (
          <Grid item xs={12} md={3}>
            <InfoLink to="/dreamers" activeWhenExact={true} spanishLabel={spnCopy[3]} englishLabel={engCopy[3]} />
          </Grid>
        )}
        <Grid item xs={12} md={3}>
          <InfoLink to="/news" spanishLabel={spnCopy[1]} englishLabel={engCopy[1]} activeWhenExact={true} />
        </Grid>
        <Grid item xs={12} md={3}>
          <InfoLink to="/book" activeWhenExact={true} spanishLabel={spnCopy[2]} englishLabel={engCopy[2]} />
        </Grid>
      </Grid>
    </div>
  );
};

export default InfoBanner;
