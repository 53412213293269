import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import { useTranslations } from "../TranslationContext/TranslationContext";

const useStyles = makeStyles(() => ({
  text: {
    margin: "25px",
    fontSize: "1rem",
    paddingTop: "50px",
    fontFamily: `'Montserrat', sans-serif;`,
    textDecoration: "underline",
  },
}));

const copy = {
  disclaimer: "Content on the site is for general educational purposes only and may depend on many factors. Please contact us for any questions.",
  disclaimerSpn: "El contenido del sitio es solo para fines educativos generales y puede depender en otros factors. Comuníquese con nosotros por cualquier pregunta.",
};

const Disclaimer = () => {
  const classes = useStyles();
  const { currentLanguage } = useTranslations();
  return (
    <div>
      <Typography justify="center" className={classes.text}>
        {currentLanguage === "eng" ? copy.disclaimer : copy.disclaimerSpn}{" "}
      </Typography>
    </div>
  );
};

export default Disclaimer;
