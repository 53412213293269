import React, { useState } from "react";
import useStyles from "./styles.js";
import { Slide, Grid } from "@material-ui/core";
import Review from "./Review";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import reviews from "./reviewsFile";
import Photos from "./Photos";

const Reviews = () => {
  let [reviewIndex, setReviewIndex] = useState(0);
  let [slide, setSlide] = useState(true);

  let rightClick = () => {
    setSlide((prev) => !prev);

    setTimeout(() => {
      setReviewIndex((prev) => (prev + 1) % reviews.length);
      setSlide(true);
    }, 500);
  };
  let leftClick = () => {
    setSlide((prev) => !prev);

    setTimeout(() => {
      setReviewIndex((prev) => Math.abs((prev - 1) % reviews.length));
      setSlide(true);
    }, 500);
  };

  const classes = useStyles();
  return (
    <div>
      <div>
        <Photos />
      </div>
      <div className={classes.head}>
        <Grid container align="center" justify="center" alignItems="center" className={classes.mainGrid}>
          <Grid item md={1}>
            <FontAwesomeIcon icon={faChevronLeft} size="2x" onClick={() => leftClick()} />
          </Grid>

          <Grid item md={8}>
            <div id="reviews">
              <Slide in={slide} direction={slide ? "left" : "right"}>
                <div>
                  <Review key="review" review={reviews[reviewIndex]} />
                </div>
              </Slide>
            </div>
          </Grid>

          <Grid item md={1}>
            <FontAwesomeIcon icon={faChevronRight} size="2x" onClick={() => rightClick()} />
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default Reviews;
