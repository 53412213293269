import React from "react";
import useStyles from "./styles";
import { Grid, Paper } from "@material-ui/core";
import Footer from "../Footer/Footer";
import UpdateCard from "./UpdateCard";
import Consultation from "../Misc/Consultation";
import Header from "../Misc/Header";
import { updatesData as updates } from "./updatesData";
import Disclaimer from "../Misc/Disclaimer";

const title = {
  title: "Latest News",
  titleSpn: "Últimas Noticias",
};

const Updates = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Header copy={title} />
      <Grid container spacing={3} align="center" justify="center" alignItems="flex-start" className={classes.grid}>
        <Grid item md={6} align="left">
          <Paper className={classes.paper1}>
            {updates.map((item) => (
              <UpdateCard info={item} />
            ))}
            <Disclaimer />
          </Paper>
        </Grid>
        {/* Consultation Half */}
        <Grid item md={4}>
          <Paper>
            <Consultation />
          </Paper>
        </Grid>
      </Grid>

      <Footer />
    </div>
  );
};

export default Updates;
