import React from "react";
import { Grid, Box, Slide } from "@material-ui/core";
import stats from "./statsInfo";
import useWindowPosition from "./useWindowPosition";
import Stat from "./Stat";
import useStyles from "./styles";

const Stats = () => {
  let checked = useWindowPosition("numbers");

  let classes = useStyles();
  return (
    <div className={classes.root}>
      <Box id="numbers">
        <Grid container justify="space-around" align="center" alignItems="center">
          {stats.map((stat, index) => (
            <Slide in={checked} direction="down" timeout={500 * index + 1000} key={`${stat} + ${index}`}>
              <Grid item>
                <Stat stat={stat} index={index} checked={checked} />
              </Grid>
            </Slide>
          ))}
        </Grid>
      </Box>
    </div>
  );
};

export default Stats;
