import {makeStyles} from '@material-ui/core/styles'

export default makeStyles((theme) => ({
    navBar: {
        position: 'fixed',
        zIndex: '30',
    },
    languageButton: {
        color: 'black',
        fontFamily: `'Shippori Mincho B1', serif`,
        fontSize: '1.4rem',
        borderColor: 'white',
    },
    mainName: {
        fontFamily: `'Shippori Mincho B1', serif`,
        color: 'white',
        paddingLeft: '10px',
        paddingRight: '10px'
    },
    subtitle: {
        fontFamily: `'Montserrat', sans-serif;`,
        color: 'white',
        paddingBottom: '7px'
    },
    navLink: {
        textDecoration: 'none',
        fontFamily: `'Montserrat', sans-serif;`,
        fontSize: '1.4rem',
        borderRadius: '5px',
        padding: '3px 5px',
        fontWeight: '2',
        color: 'white',
        '&:hover': {
            color:'white',
            backgroundColor: 'rgba(236, 236, 236, 0.5)'
        }
    },
    menuList: {
        listStyle:'none',
    },
    menuSection: {
        borderRadius: '25px'
    },
    icon: {
        color: 'white',
    },



}))