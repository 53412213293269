import arturo from './images/arturoR.jpg'
import alejandro from './images/alejandroG.jpg'
import jenny from './images/jenny.jpg'
import angelina from './images/AngelinaProfile2018.jpg'
import perla from './images/perla.jpeg';

export const team = [
    {
        firstName: 'Arturo',
        lastName: 'Jiménez',
        position: 'Attorney And Author',
        positionSpn: 'Abogado Y Autor',
        photo: arturo,
        description: `Arturo Jimenez is an experienced attorney who has practiced in the area of federal immigration law for over 20 years in Colorado. After serving as the President of the Colorado Statewide Parent Coalition from 2000 to 2006, Arturo was twice elected to the Board of Education for the Denver Public Schools from 2007 to 2015. He is a member of the Colorado Chapter of the American Immigration Lawyers Guild, the Colorado and Denver Bar Associations, and a former member of the National Association of Latino Elected Officials. His experiences have allowed him to become an author of an eye opening book: DREAMers Nightmare The U.S. War on Immigrant LatinX Children.`,
        descriptionSpn: `Arturo Jiménez es un abogado experimentado que ha practicado en el área de la ley federal de inmigración durante más de 20 años en Colorado. Después de servir como presidente de la Coalición de Padres del Estado de Colorado de 2000 a 2006, Arturo fue elegido dos veces para la Junta de Educación de las Escuelas Públicas de Denver de 2007 a 2015. Es miembro del Capítulo de Colorado del Gremio de Abogados de Inmigración Estadounidense, los Colegios de Abogados de Colorado y Denver, y ex miembro de la Asociación Nacional de Funcionarios Latinos Electos. Sus experiencias le han permitido convertirse en autor de un libro revelador: DREAMers Nightmare The U.S. War on Immigrant LatinX Children.`,
        width: 12

    },
    {
        firstName: 'Jenny',
        lastName: 'Santos',
        position: 'Paralegal',
        positionSpn: 'Paralegal',
        photo: jenny,
        description: `Jenny Santos obtained her Paralegal Certificate from the Community College of Denver. She is an advocate for survivors of domestic violence, sexual assault, human trafficking, and general victims of crime.`,
        descriptionSpn: 'Jenny Santos obtuvo su certificado de asistente legal del Community College of Denver. Es defensora de sobrevivientes de violencia doméstica, agresión sexual, trata de personas y víctimas en general de delitos.',
        width: 6


    },
    {
        firstName: 'Angelina',
        lastName: 'Jiménez',
        position: 'Executive Assistant',
        positionSpn: 'Asistente Ejecutiva',
        photo: angelina,
        description: `Angelina  holds her licensure in accounting with a Specialty in taxes. Angelina has been working with JM&A since 2016, specializing in homeowners associations auditing and taxation, she coordinated parenting bilingual classes for 6 years, as a volunteer for the Colorado Statewide Parent Coalition, she was elected as a PTO board member in her children's school for 3 years in a row. When she just arrived in the USA, Angelina volunteered as a Paraprofessional in an elementary school and as an art teacher for several years. Before moving to the USA, Angelina worked for 6 years with a multinational Public Accounting Firm (Ernst & Young) and 2 years with a multinational Law Firm (Baker & McKenzie) in Mexico, and she was studying a master in finance, she has experience in tax consulting, tax compliance and revocation appeal for transnational companies in Mexico.
        She loves spending time with her family, hiking, doing yoga, reading, writing, dancing and singing.`,
        descriptionSpn: `Angelina tiene su licenciatura en contabilidad con especialidad en impuestos. Angelina ha estado trabajando con JM&A desde 2016, especializándose en auditorías e impuestos de asociaciones de propietarios, coordinó clases bilingües para padres durante 6 años, como voluntaria de la Coalición de Padres del Estado de Colorado, fue elegida como miembro de la junta de la PTO en la escuela de sus hijos durante 3 años. años seguidos. Cuando recién llegó a los EE. UU., Angelina se ofreció como voluntaria como asistente de maestro en una escuela primaria y como maestra de arte durante varios años. Antes de mudarse a los EE. UU., Angelina trabajó durante 6 años en una Firma de Contadores Públicos multinacional (Ernst & Young) y 2 años en una Firma de Abogados multinacional (Baker & McKenzie) en México, y estaba estudiando una maestría en finanzas, tiene experiencia en consultoría tributaria, cumplimiento tributario y recurso de revocación para empresas transnacionales en México. Le encanta pasar tiempo con su familia, hacer caminatas, hacer yoga, leer, escribir, bailar y cantar.`,
        width: 6
    },
    {
        firstName: 'Alejandro',
        lastName: 'Guardado',
        position: 'Intern Legal Assistant',
        positionSpn: 'Asistente Legal',
        photo: alejandro,
        description: `Alejandro is earning his associate’s degree in Sociology at Red Rocks Community College.  He plans to eventually go to law school and become an immigration lawyer.  He is our intern paralegal assistant.`,
        descriptionSpn: `Alejandro va a Red Rocks para terminar su título de asociado en sociología. Planea eventualmente ir a la facultad de derecho y convertirse en abogado de inmigración. Es asistente interno en la oficina`,
        width: 6
    },
    { 
        firstName: 'Perla',
        lastName: 'Armendariz',
        position: 'Intern Legal Assistant',
        positionSpn: 'Asistente Legal',
        photo: perla,
        description: `Perla Armendariz achieved getting a certificate for legal office at Emily Griffith technical college. She grew up in Denver Colorado and enjoys hiking the beautiful mountains of Colorado, enjoys her Mexican culture; the food, music, holiday celebrations, and spending time with family and friends. She is content by the prospect of working to help those who have been harmed by the negligence of others and who are looking for a better life for themselves and family. `,
        descriptionSpn: `Perla Armendáriz logró obtener un certificado de oficina legal en la escuela técnica Emily Griffith. Creció en Denver Colorado y disfruta escalar las hermosas montañas de Colorado, disfruta de su cultura mexicana; la comida, la música, las celebraciones navideñas y pasar tiempo con familiares y amigos. Está contenta con la perspectiva de trabajar para ayudar a aquellos que han sido dañados por la negligencia de otros y que buscan una vida mejor para ellos y su familia.`,
        width: 6
    }


]
