import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { CssBaseline, Grid, Typography, IconButton } from "@material-ui/core";
import FacebookIcon from "@material-ui/icons/Facebook";
import backgroundImage from "../../Images/back-pattern.png";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#553a62",
    paddingBottom: "100px",
    backgroundImage: `url(${backgroundImage})`,
    paddingTop: "8px",
    margin: "0 auto",
  },
  footer: {
    width: "80vw",
  },
  contact: {
    textTransform: "uppercase",
    fontFamily: `'Shippori Mincho B1', serif`,
    fontWeight: "900",
    color: "white",
    fontSize: "2rem",
  },
  body: {
    color: "white",
    fontWeight: "900",
    fontFamily: `'Montserrat, sans-serif;'`,
  },
  button: {},
  facebook: {
    color: "#4267B2",
    backgroundColor: "white",
    height: "50px",
    width: "50px",
  },
}));

const Footer = () => {
  const classes = useStyles();
  return (
    <div className={classes.root} id="footer">
      <CssBaseline />
      <Grid container className="footer" align="left" justify="space-around">
        <Grid item>
          <Typography className={classes.contact}>contact us</Typography>
          <Typography className={classes.body}>Immigration Law Office of Arturo Jiménez</Typography>
          <Typography className={classes.body}>3417 W 38th Ave,</Typography>
          <Typography className={classes.body}>Denver, CO 80211</Typography>
          <Typography className={classes.body}>(303) 455-1809</Typography>
        </Grid>
        <Grid item>
          <Typography className={classes.contact}> office hours</Typography>
          <Typography className={classes.body}>Monday - Thursday</Typography>
          <Typography className={classes.body}>9:00am - 12:30pm & 1:30pm - 4:30</Typography>
          <Typography className={classes.body}>Friday - Sunday</Typography>
          <Typography className={classes.body}>Closed</Typography>
        </Grid>
        <Grid item>
          <Typography className={classes.contact}>Follow us</Typography>
          <IconButton className={classes.button} aria-label="add to shopping cart">
            <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/Arturojimenezlaw/">
              <FacebookIcon className={classes.facebook} />
            </a>
          </IconButton>
        </Grid>
      </Grid>
    </div>
  );
};

export default Footer;
