import React from "react";
import { Typography, Grid, Box } from "@material-ui/core";
import useStyles from "./styles";
import officeServices from "./officeServices";
import ServiceCard from "./ServiceCard";
import { useTranslations } from "../TranslationContext/TranslationContext";
import Consultation from "../Misc/Consultation";

const copy = {
  eng: {
    title: "We can help with",
    title2: "Schedule your consultation today!",
    title3: "We often find a legal avenue for legalization that our clients did not know was possible",
  },
  spn: {
    title: "Podemos ayudar con",
    title2: "¡Programe su consulta hoy!",
    title3: "Encontramos una vía legal para la legalización que nuestros clientes no sabían que era posible.",
  },
};

const Services = () => {
  const classes = useStyles();
  const { currentLanguage } = useTranslations();

  return (
    <div>
      <div className={classes.list}>
        <Box boxShadow={23} className={classes.mainGrid}>
          <Grid container alignItems="center" align="center" justify="center" spacing={5}>
            <Grid item md={6}>
              <Typography className={classes.listTitle}> {currentLanguage === "eng" ? copy.eng.title : copy.spn.title} </Typography>
              {officeServices.map((service) => (
                <ServiceCard service={service} key={service.id} />
              ))}
            </Grid>
            <Grid item md={6}>
              <Consultation />
            </Grid>
          </Grid>
        </Box>
      </div>
    </div>
  );
};

export default Services;
